import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
import axios from "axios";
import OneSignalVue from "onesignal-vue";
import Vue from "vue";
import VueAxios from "vue-axios";
import excel from "vue-excel-export";
import VueFeather from "vue-feather";
import InstantSearch from "vue-instantsearch";
import JsonExcel from "vue-json-excel";
import VueQRCodeComponent from "vue-qr-generator";
import VueSkycons from "vue-skycons";
import VueSweetalert2 from "vue-sweetalert2";
import Vue2Filters from "vue2-filters";
import Vuebar from "vuebar";
import App from "./App.vue";
import "./plugins/base";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
import VueMask from "v-mask";
import JsonViewer from "vue-json-viewer";

import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";

import firebase from "firebase/app";
import "firebase/firestore";
// import VueFirebase from 'vue-firebase'
const config = {
  apiKey: "AIzaSyDZNo0jM8DVtar3XUYnU_366og3dhBC-c4",
  authDomain: "iservice-894e8.firebaseapp.com",
  projectId: "iservice-894e8",
  storageBucket: "iservice-894e8.appspot.com",
  messagingSenderId: "129123200738",
  appId: "1:129123200738:web:0618e94a6478660a3038bb",
  measurementId: "G-W84KLNSMJX",
};

const secondaryAppConfig = {
  apiKey: "AIzaSyDXab3V5_VKXXgJhbBxNuzThejtkKv7v6s",
  authDomain: "smart-meter-application-49d97.firebaseapp.com",
  projectId: "smart-meter-application-49d97",
  storageBucket: "smart-meter-application-49d97.appspot.com",
  messagingSenderId: "103537642803",
  appId: "1:103537642803:web:342463bfe2f9b50d560246",
  measurementId: "G-0BXYPXF70S",
};

// Vue.use(VueFirebase, {firebase: firebase, config});
firebase.initializeApp(config);
const secondaryApp = firebase.initializeApp(secondaryAppConfig, "secondary");
export default secondaryApp;

Vue.component("qr-code", VueQRCodeComponent);
Vue.component("downloadExcel", JsonExcel);
Vue.use(VueSweetalert2);

Vue.use(VueMask);
Vue.use(VueAxios, axios);
Vue.use(VueFeather);
Vue.use(VueSkycons, { color: "#1e88e5" });
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.use(excel);
Vue.use(OneSignalVue);
Vue.use(Vue2Filters);
Vue.use(LottieVuePlayer);
Vue.use(JsonViewer);

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "mdi",
});

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
  // beforeCreate() {
  //   this.$OneSignal
  //     .init({
  //       appId: "dc4b5340-acf2-49cc-91f1-b728b64b92ed",
  //       safari_web_id:
  //         "web.onesignal.auto.32358c52-eb5f-4c9c-a5b8-d10e6e7ce454",
  //       notifyButton: {
  //         enable: true,
  //         position: "bottom-left",
  //       },
  //       allowLocalhostAsSecureOrigin: true,
  //     })
  //     .then(() => {
  //       this.$OneSignal.showSlidedownPrompt();
  //     });
  // },
}).$mount("#app");
