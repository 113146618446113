<template>
  <v-app
    id="flexy"
    :class="`${
      !$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'
    }`"
  >
    <router-view />
  </v-app>
</template>

<script>
// import { Decode } from "@/services";
export default {
  name: 'App',

  components: {},
  // beforeCreate() {
  //   let data = JSON.parse(Decode.decode(localStorage.getItem("skUser")));
  //   this.$OneSignal.setExternalUserId(data.id);
  // },
}
</script>
