import Vue from "vue";

import saleOrderIcon from "@/assets/images/icon/saleOrderIcon.vue";
import deliveryOrderIcon from "@/assets/images/icon/deliveryOrderIcon.vue";
import quotationIcon from "@/assets/images/icon/quotationIcon.vue";
import bookingOrderIcon from "@/assets/images/icon/bookingOrderIcon.vue";
import saleOrderDeliveryIcon from "@/assets/images/icon/saleOrderDeliveryIcon.vue";
import deletePhotoIcon from "@/assets/images/icon/deletePhotoIcon.vue";
import iconStockItem from "@/assets/images/icon/iconStockItem.vue";
import iconSparesPart from "@/assets/images/icon/iconSparesPart.vue";
import iconSoldOut from "@/assets/images/icon/iconSoldOut.vue";
import iconInStock from "@/assets/images/icon/iconInStock.vue";

Vue.component("sale-order-icon", saleOrderIcon);
Vue.component("delivery-order-icon", deliveryOrderIcon);
Vue.component("quotation-icon", quotationIcon);
Vue.component("booking-order-icon", bookingOrderIcon);
Vue.component("sale-order-delivery-icon", saleOrderDeliveryIcon);
Vue.component("delete-photo-icon", deletePhotoIcon);
Vue.component("icon-stock-item", iconStockItem);
Vue.component("icon-spares-part", iconSparesPart);
Vue.component("icon-sold-out", iconSoldOut);
Vue.component("icon-in-stock", iconInStock);

const values = {
  deletephotoicon: {
    component: "delete-photo-icon",
  },
  saleordericon: {
    component: "sale-order-icon",
  },
  deliveryordericon: {
    component: "delivery-order-icon",
  },
  quotationicon: {
    component: "quotation-icon",
  },
  bookingordericon: {
    component: "booking-order-icon",
  },
  saleorderdeliveryicon: {
    component: "sale-order-delivery-icon",
  },
  iconstockitem: {
    component: "icon-stock-item",
  },
  iconsparespart: {
    component: "icon-spares-part",
  },
  iconsoldout: {
    component: "icon-sold-out",
  },
  iconinstock: {
    component: "icon-in-stock",
  },
};

export default values;
